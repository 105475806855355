._padding-top-none{
    padding-top: 0;
}

._light-italic{
    font-style: italic;
    font-weight: 300;
}

._gold{
    color: $gold;
}

._small{
    font-size: 15px;
}

._border{
    width: 100%;
    height: 1px;
    background-color: $borderColor;
}