*{
    font-family: $font;
    // color: $light;
}

input, textarea, select, label{
    font-size: 16px;
    line-height: 18px;
    font-weight: 300;
}

textarea{
    padding: 8px 3px;
}

small{
    font-size:14px;
}

._hr{
    width: 100%;
    height: 1px;
    background-color: $light;
}

._form-row{
    @media(min-width: $tablet){
        display: flex;
        column-gap: 15px;
        flex-wrap: nowrap;
    }    
    ._form-group{
        width: 100%;
        margin: 10px 0;
        ._form-element{
            width: 100%;
            color: $light;
        }
    }
    ._button{
        margin-top: 30px;
    }
}

._success-message{
    color: green;
}

._error-message{
    color: red;
    font-style: italic;
    font-weight: 600;
    font-size: 15px;
}

._success-group{
    padding: 15px;
    border: 1px solid green;
    border-radius: 5px;
    margin-top: 15px
}

._error-group{
    padding: 15px;
    border: 1px solid red;
    border-radius: 5px;
    margin-top: 15px;
    width: 100%;
    display: block;
}

._button{
    background-color: transparent;
    border-radius: 0;
    height: 40px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    border: 1px solid $gold;
    color: #fff;
    text-transform: uppercase;
    padding: 9px 11px;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
}

._with-dropdown-arrow{
    position: relative;
    &::after{
        content: "";
        display: inline-block;
        position: relative;
        left: 10px;
        // top: 45%;
        width: 10px;
        height: 10px;
        border-right: 1px solid $gold;
        border-bottom: 1px solid $gold;
        transform: rotate(45deg) translateY(-50%);
        @include transition(0.25s);
        @media(min-width: $desktop){
            left: 15px;
        }
    }
    &._open{
        &::after{
            transform: rotate(-135deg);
        }
    }
    @media(min-width: $laptop){
        padding-right: 20px;
        &::after{
            right: 0px;
        }
        ~ * {
            visibility: hidden;
            opacity: 0;
            @include transition(0.5s);
            &._show{
                visibility: visible;
                opacity: 1;
            }
        }
    }
    
}

._hamburger-icon{
    width: 40px;
    height: 40px;
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    > div {
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
        span{
            position: absolute;
            height: 1px;
            width: 100%;
            background-color: $gold;
            left: 50%;
            transform: translateX(-50%);
            @include transition(0.25s);
            &:nth-of-type(1){
                top: 13px;
            }
            &:nth-of-type(2){
                bottom: 13px;
            }
        }
    }
    &._close{
        span{
            @include transition(0.25s);
            &:nth-of-type(1){
                top: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
            }
            &:nth-of-type(2){
                bottom: unset;
                top: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }
}

._slick-item{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    height: 180px;
    
    img{
        max-width: 150px;
    }
    opacity: 0.6;
    @media(min-width: $laptop){
        opacity: 0.4;
        @include opacityTransitionHover;
    }
}

._breadcrums{
    padding-top: 100px;
    @include wideBoxLayout;
    p{
        color: $lightGrey;
        text-transform: uppercase;
        font-size: 12px;
        margin: 0;
        letter-spacing: 0.065em;
        padding: 15px 0;
        a{
            color: $lightGrey;
            display: inline-block;
        }
        @media(min-width: $laptop){
            color: $grey;
            a{
                color: $grey;
                @include colorTransitionHover;
            }
        }
    }
}

._simple-heading{
    color: $light;
    background-color: $veryDark;
    padding: 30px 0;
    text-transform: uppercase;
    h1{
        text-align: center;
        font-size: 24px;
        line-height: 24px;
    }
    @media(min-width: $tablet){
        padding: 40px 0;
        h1{
            font-size: 27px;
            line-height: 27px;
        }
    }
    @media(min-width: $laptop){
        padding: 50px 0;
        h1{
            font-size: 30px;
            line-height: 30px;
        }
    }
}

._pre-heading{
    font-weight: 300;
    color: $grey;
    font-size: 16px;
    line-height: 21px;
    margin: 0;
    @media(min-width: $tablet){
        font-size: 18px;
    }
    @media(min-width: $laptop){
        font-size: 20px;
    }
}

._page-heading{
    color: $light;
    font-size: 24px;
    line-height: 28px;
    @media(min-width: $tablet){
        font-size: 27px;
        line-height: 32px;
    }
    @media(min-width: $laptop){
        font-size: 30px;
        line-height: 36px;
    }
}

._heading{
    color: $light;
    font-size: 28px;
    line-height: 32px;
    margin: 15px 0;
    @media(min-width: $tablet){
        font-size: 34px;
        line-height: 38px;
    }
    @media(min-width: $laptop){
        font-size: 40px;
        line-height: 45px;
    }
}

._secondary-heading{
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
}

p{
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    color: $light;
}

._large-text-par{
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    @media(min-width: $tablet){
        font-size: 27px;
        line-height: 33px;
    }
    @media(min-width: $laptop){
        font-size: 30px;
        line-height: 37px;
    }
}

._horline{
    width: 100px;
    height: 3px;
    background-color: $gold;
}

._social-icons{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0 30px 0;
    @media(min-width: 500px){
        justify-content: flex-start;
        order: 2;
    }
    a{
        opacity: 0.6;
        @media(min-width: $laptop){
            opacity: 0.4;
            @include opacityTransitionHover;
        }
    }
}

._slick-buttons{
    display: flex;
    column-gap: 15px;
    ._slick-arrow{
      width: 30px;
      height: 30px;
      border: 1px solid $light;
      border-radius: 100px;
      position: relative;
      @include transition;
      cursor: pointer;
      &:after{
        content: "";
        display: block;
        position: absolute;
        left:50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-right: 1px solid $light;
        border-top: 1px solid $light;
        height: 10px;
        width: 10px;
        @include transition(0.1s);
      }
      &._slick-arrow-next{
        &:after{
          left:45%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
      &._slick-arrow-previous{
        &:after{
          left:55%;
          transform: translate(-50%, -50%) rotate(-135deg);
        }
      }
      @media(min-width: $laptop){
        border-color: $grey;
        &:after{
          border-color: $grey;
        }
        &:hover{
          border-color: #fff;
          &:after{
            border-color: #fff;
          }
        }
      }
    }
}

._section-banner{
    ._flex-box{
        @include wideBoxLayout;
        background-color: $veryDark;
    }
    ._image{
        background-position: center;
        background-size: cover;
        height: 200px;
        padding-bottom: 42%;
        height: 0;
    }
    ._intro{
        padding: 45px 20px;
        h1{
            margin-top: 0;
        }
        img{
            width: 240px;
        }
    }
    @media(min-width:$tablet){
        ._flex-box{
            display: flex;
            height: 350px;
            align-items: center;
        }
        ._image{
            width: 50%;
            height: 100%;
            padding: 0;
        }
        ._intro{
            width: 50%;
            padding: 0 30px;
            max-height: calc(100% - 90px);
            overflow-y: auto;
        }
    }
    @media(min-width:$laptop){
        ._flex-box{
            height: 400px;
        }
        ._image{
            width: 60%;
        }
        ._intro{
            width: 40%;
            padding: 0 40px;
        }
    }
}

._accordion{
    background-color: $veryDark;
    padding: 10px 10px 20px 20px;
    border: 1px solid $borderColor;
    margin-bottom: 30px;
    ._accordion-list{
        overflow: hidden;
        &._collapse{
            height: 0;
        }
    }
    > ._accordion-item{
        > ._accordion-button{
            color: $gold;
            padding: 10px 0;
            font-weight: 400;
            text-transform: uppercase;
            
        }
        > ._accordion-list{
            > ._accordion-item{
                > ._accordion-button{
                    font-weight: 300;
                    color: $grey;
                    padding: 5px 0;
                    text-transform: uppercase;
                    @media(min-width:$laptop){
                        &:hover{
                            color: $gold;
                        }
                    }
                }
                > ._accordion-list{
                    > ._accordion-item{
                        padding-left:15px;
                        > ._accordion-button{
                            font-weight: 300;
                            color: $grey;
                            padding: 2px 0;
                            @media(min-width:$laptop){
                                &:hover{
                                    color: $gold;
                                }
                            }
                        }
                    }
                }
            }
        }
        ._accordion-button{
            position: relative;
            @include transition(0.1s);
            cursor: pointer;
            display: block;
            ._arrow{
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 50px;
                &:hover{
                    &:after{
                        border-color: $gold;
                    }
                }
                
                &:after{
                    content:"";
                    display: block;
                    position: relative;
                    position: absolute;
                    right: 10px;
                    top: 39%;
                    width: 10px;
                    height: 10px;
                    border-right: 1px solid $grey;
                    border-bottom: 1px solid $grey;
                    transform: rotate(45deg) translateY(-50%);
                    @include transition(0.25s);
                }
                &._open{
                    &:after{
                        transform: rotate(-135deg);
                        right: 7px;
                    }
                }
            }
        }
    }
    ._active{
        color: $gold!important;
        position: relative;
        padding-left: 10px!important;
        &:after{
            content: "";
            display: block;
            width: 2px;
            height: 20px;
            background-color: $gold;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }
    @media(min-width:$tablet){
        padding: 15px 20px 30px 30px;
    }
    @media(min-width:$laptop){
        padding: 30px 30px 40px 40px;
    }
}

._coupon{
    display: flex;
    max-width: 450px;
    align-items: center;
    
    input{
        width: calc(100% - 100px);
        height: 40px;
        border: 1px solid $borderColor;
        background-color: $veryDark;
        padding-left: 7px;
        color: $light;
    }
    button{
        width: 100px;
        height: 40px;
    }
}

._button-2{
    background-color: $gold;
}

._button-3{
    border-color: $borderColor;
    color: $grey;
}

._button-4{
    border-color: $dark;
    color: #fff;
    background-color: $dark;
}

._form-panel{
    max-width: 800px;
    margin: 0 auto;
}

._form-element{
    min-height: 40px;
    background-color: $veryDark;
    border: 1px solid $borderColor;
    border-radius:0;
    color: $light;
}

// select._form-element{
//     padding-top: 8px;
// }

._form-row{
    label{
        margin-bottom: 5px;
        color: $light;
    }
}

._checkbox-2{
    ._item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    
    label {
        display: flex;
        margin: auto;
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
    
    input {
        position: absolute;
        transform: scale(0);
    }
    
    input:checked ~ ._checkbox {
        transform: rotate(45deg);
        width: 14px;
        height: 28px;
        margin: 0 7px 0 13px;
        border-color: $gold;
        border-width: 3px;
        border-top-color: transparent;
        border-left-color: transparent;
        border-radius: 0;
        background-color: transparent;
    }
    
    ._checkbox {
        display: block;
        width: inherit;
        height: inherit;
        border: 1px solid $grey;
        background-color: $veryDark;
        border-radius: 0px;
        transition: all 0.2s cubic-bezier(0, 0.01, 0.23, 0.8);
    }
      
}

._button-submit{
    min-width: 140px;
}

._button-place-order{
    visibility: hidden;
    opacity: 0;
    @include transition;
    width: 100%;
    &._show{
        visibility: visible;
        opacity: 1;
    }
    @media(min-width: $tablet){
        max-width: 220px;
    }
}

._pagination{
    color: $light;
    display: flex;
    justify-content: center;
    padding: 30px 0;
    column-gap: 5px;
    overflow-x: auto;
    ._pagination-item{
        display: block;
        min-width: 40px;
        padding: 0 10px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $borderColor;
        &._dim{
            background-color: $gold;
            border: 1px solid $gold;
        }
    }
    @media(min-width: $tablet){
        justify-content: flex-end;
    }
}

._load-more{
    color: $light;
    display: flex;
    justify-content: center;
    padding: 30px 0;
    column-gap: 5px;
    overflow-x: auto;
    ._load-more-item{
        display: block;
        min-width: 40px;
        padding: 0 10px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $borderColor;
        &._dim{
            background-color: $gold;
            border: 1px solid $gold;
        }
    }
}

._popup{
    visibility: visible;
    opacity: 1;
    @include transition;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    background-color: $darkOpaque;
    z-index: 1000;
    ._inner{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 40px);
        background-color: $veryDark;
        z-index: 1;
        padding: 30px 20px;
        text-align: center;
        border: 1px solid $borderColor;
        h3{
            margin-top: 0;
        }
        p{
            margin-bottom: 0;
        }
        ._flex{
            display: flex;
            justify-content: flex-end;
            height: 0;
        }
        ._close-popup-button{
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
        @media(min-width: $tablet){
            width: calc(100% - 60px);
            max-width: 600px;
        }
        @media(min-width: $laptop){
            width: calc(100% - 80px);
        }
    }
    ._overlay{
        position: absolute;
        left: 0;
        top: 0;
        height: 100vh;
        width: 100%;
        z-index: 0;
    }
    &._hide{
        opacity: 0;
        visibility: hidden;
    }
}

._popup-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0 0 0;
    column-gap: 15px;
    row-gap: 15px;
    flex-wrap: wrap;
    ._button{
        min-width: 150px;
    }
    @media(min-width: $tablet){
        
    }
}

._newsletter-form{
    @include transition;
    visibility: visible;
    opacity: 1;
    &._hide{
        visibility: hidden;
        opacity: 0;
    }
}

._video{
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56%;
    background-color: $dark;
    video, iframe{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    &._youtube{
        margin: 30px 0;
    }
}

._ligtbox-wrapper{
    img{
        width: auto;
        max-width: 100%;
        max-height: 100vh;
        height: auto;
        @media(min-width: $laptop){
            min-width: 570px;
        }
    }
}

._create-account {
    height: 0;
    overflow: hidden;
    @include transition;
    &._show{
        height: 170px;
    }
}

._newsletter-wrapper{
    border: 1px solid $borderColor;
    padding: 30px;
    background-color: $veryDark;
    p{
        margin-top: 0;
    }
}

._cookie{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $veryDark;
    border-top: 1px solid $borderColor;
    z-index: 1;
}

._cart-items-quantity{
    color: $light;
}

._border-around{
    border: 1px solid $borderColor;
}

._description{
    font-weight: 300;
    font-size: 16px;
    font-family: $font;
    line-height: 26px;
    color: $light;
}

._loader-container{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: $dark;
    z-index: 1000;
    visibility: visible;
    opacity: 1;
    @include transition(.5s, .5s);
    &._hide{
        visibility: hidden;
        opacity: 0;
    }
    ._loader-centered-box-container{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        ._loader {
            width: 8px;
            height: 40px;
            border-radius: 4px;
            display: block;
            margin: 20px auto;
            position: relative;
            background: currentColor;
            color: $gold;
            box-sizing: border-box;
            animation: animloader 0.3s 0.3s linear infinite alternate;
            &:after, &:before{
                content: '';
                width: 8px;
                height: 40px;
                border-radius: 4px;
                background: currentColor;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 20px;
                box-sizing: border-box;
                animation: animloader 0.3s  0.45s  linear infinite alternate;
            }
            &:before{
                left: -20px;
                animation-delay: 0s;
            }
        }
        
        @keyframes animloader {
            0%   { height: 48px} 
            100% { height: 4px}
        }
    }
}

._payment-method{
    display: flex;
    align-items: center;
    column-gap: 15px;
}

._payment-icons{
    display: flex;
    align-items: center;
    column-gap: 15px;
    img{
        min-width: 40px;
    }
}

._error{
    input, &._checkbox-2 ._checkbox, select, textarea{
        border: 1px solid red;        
    }
}

._search-container{
    margin-bottom: 15px;
    position: relative;
    &:before{
        content: url("../img/Icons/iconmonstr-search-thin.svg");
        position: absolute;
        top: 10px;
        left: 10px;
    }
}

._search{
    width: 100%;
    color: #fff;
    padding-left: 35px;
    height: 44px;
    &::placeholder{
        color: $grey;
    }
}

._section-cart p._transaction-message{
    font-size: 20px;
    text-align: center;
    margin-bottom: 60px;
    font-weight: 600;
    color: $gold;
}