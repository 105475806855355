._section-hero-slider{
  height: 100vh;
  min-height: 600px;
  @include clipPathVShapeBottom;
}

._hero-slider-item{
  height: 100vh;
  min-height: 600px;
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100vw;
  overflow: hidden;
  video{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
  }
  ._bottom-gradient-box{
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.7399160347732843) 0%, rgba(0,0,0,0) 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 300px;
    z-index: 0;
  }
  ._grid{
    height: 100%;
    position: relative;
    ._column{
      height: 100%;
    }
  }
  ._headline-overlay{
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    bottom: 20px;
    left: 0;
    padding: 30px 20px 124px 20px;
    // height: calc(100% - 140px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    text-align: center;
    z-index: 1;
    ._button{
      margin-top: 30px;
    }
    h1{
      max-width: 270px;
    }
  }
  ._slick-buttons{
    position: absolute;
    bottom: 60px;
    left: 20px;
    @media(min-width: $tablet){
      position: unset;
    }
  }
  @media(min-width: $tablet){
    height: calc(100vh - 10px);
    
    ._headline-overlay{
      // clip-path: polygon(0% 1%,100% 8%,100% 100%,0% 100%);
      max-width: 470px;
      width: auto;
      height: auto;
      background-color: rgba(0,0,0,0.5);
      backdrop-filter: blur(8px);
      bottom: 0;
      padding: 30px 30px 100px 30px;
      text-align: left;
      align-items: baseline;
      ._button{
        margin-top: 15px;
      }
      h1{
        max-width: unset;
      }
    }
    ._bottom-flex-panel{
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
  }
}

._social-icons{
  display: flex;
  position: absolute;
  right: 20px;
  bottom: 50px;
  z-index: 1;
  a{
    opacity: 1;
  }
  @media(min-width: $tablet){
    right: 0;
    bottom: 62px;
  }
  @media(min-width: $tablet){
    right: 0;
    bottom: 82px;
  }
}

._section{
  ._heading{
    margin-top: 0;
  }
  ._pre-heading{
    margin-bottom: 10px;
  }
}

._section-message{
  text-align: center;
  @include topBottomPadding;
  ._large-text-par{
    margin: 0;
  }
}

._section-indoor-outdoor{
  color: $light;
  ._flex-box{
    ._column{
      height: 88vw;
      background-position: center;
      background-size: 100% 100%;
      position: relative;
      display: block;
      width: calc(100% - 40px);
      margin-left: auto;
      margin-right: auto;
      @include customCursor;
      overflow: hidden;
      video{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        @include transition(.35s,.25s);
      }
      &:nth-of-type(1){
        margin-bottom:20px;
        @media(min-width: $tablet){
          margin-bottom:0;
        }
      }
      
      ._vert-heading{
        position: absolute;
        bottom: 0;
        left: 30px;
        font-weight: 300;
        font-size: 34px;
        z-index: 1;
        white-space: nowrap;
        span{
          // color: $gold;
          font-weight: 600;
          // text-transform: uppercase;
        }
        &:after{
          content: "";
          display: block;
          position: relative;
          width: 120px;
          height: 3px;
          background-color: $gold;
          @include transition(0.5s, 0.25s);
          @media(min-width: $tablet){
            width: 150px;
          }
          @media(min-width: $laptop){
            width: 200px;
            visibility: hidden;
            opacity: 0;
          }
        }
        @media(min-width: $tablet){
          font-size: 40px;
        }
        @media(min-width: $laptop){
          font-size: 60px;
        }
      }
      ._overlay{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        @include overlayGradient;
        z-index: 0;
        
      }
      @media(min-width: $laptop){
        &:hover{
          video{
            transform: scale(1.1);
          }
        }
      }
    }
    @media(min-width: $tablet){
      display: flex;
      ._column{
        width: 50%;
        height: 44vw;
      }
    }
    @media(min-width: $laptop){
      ._column{
        @include transition(0.25s, 0.25s);
        &:hover{
          // background-size: 110% 110%;
          ._vert-heading{
            &:after{
              visibility: visible;
              opacity: 1;
            }
          }
          
        }
      }
    }
  }
}


._section-our-brand-philosophy{
  @include topBottomPaddingExtra;
  color: $light;
  ._flex-box{
    display: flex;
    column-gap: 30px;
    align-items: flex-start;
    flex-wrap: wrap;
    ._item{
      width: 100%;
    }
    ._icon{
      width: 41px;
    }
    ._copy{
      width: calc(100% - 75px);
    }
    ._secondary-heading{
      margin-top: 0;
    }
  }
  @media(min-width: $tablet){
    ._flex-box{
      flex-wrap: nowrap;
      ._item{
        &:nth-of-type(1){
          width: 40%;
        }
        &:nth-of-type(2){
          width: 60%;
        }
      }
    }
    ._grid-width-seperator{
      display: flex;
      flex-wrap: wrap;
      ._column{
        width: 47%;
      }
      ._seperator{
        display:none;
      }
    }
  }
  @media(min-width: $laptop){
    ._grid-width-seperator{
      flex-wrap: nowrap;
      ._column{
        width: 33%;
      }
      ._seperator{
        display: block;
        background-color: $borderColor;
        width: 1px;
        height: 110px;
      }
    }
  }
}

._section-background-image{
  position: relative;
  @include clipPathSkewBlock;
  ._background-image{
    height: 250px;
    @media(min-width: $tablet){
      height: 350px;
    }
    @media(min-width: $laptop){
      height: 30vw;
    }
  }
  height: 250px;
  @media(min-width: $tablet){
    height: 350px;
  }
  @media(min-width: $laptop){
    height: 30vw;
  }
  video{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
  }
}

._section-our-products{
  @include topBottomPadding;
  color: $light;
  z-index: 1;
  position: relative;
  ._column{
    overflow: hidden;
  }
}

._category-item{
  position: relative;
  height: 0;
  padding-bottom: 100%;
  display: block;
  overflow: hidden;
  border: 1px solid $borderColor;
  ._bg-image{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    @include transition(0.35s, .25s);
  }
  h3{
    position: absolute;
    bottom: 20px;
    left: 20px;
    margin: 0;
    font-weight: 300;
    font-size: 30px;
    z-index: 1;
    width: calc(100% - 40px);
  }
  ._overlay{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include overlayGradient2;
  }
  @include customCursor;
  @media(min-width:$laptop){
    &:hover{
      ._bg-image{
        transform: scale(1.1);
      }
    }
  }
}

._product-item{
  color: $light;
  position: relative;
  height: 0;
  padding-bottom: 100%;
  display: block;
  overflow: hidden;
  border: 1px solid $borderColor;
  ._bg-image{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    @include transition(0.35s);
  }
  h3{
    position: absolute;
    bottom: 20px;
    left: 20px;
    margin: 0;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    z-index: 1;
    width: calc(100% - 40px);
  }
  ._overlay{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include overlayGradient2;
  }
  @include customCursor;
  @media(min-width:$laptop){
    &:hover{
      ._bg-image{
        transform: scale(1.1);
      }
    }
  }
}

._section-testimonials{
  @include topBottomPaddingOverlap;
  @include clipPathSkewBlock;
  background-color: $veryDark;
  position: relative;
  ._column{
    position: relative;
  }
  ._pre-heading, ._heading{
    text-align: center;
  }
  ._heading{
    margin-bottom: 30px;
  }
  ._testimonial-item{
    text-align: center;
    max-width: 550px;
    margin: 0 auto;
    ._quotation{
      font-size: 60px;
      color: $gold;
      font-weight: 600;
    }
    ._message{
      p{
        &:nth-of-type(1){
          margin-top: 0;
        }
      }
    }
    ._author{
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 3px;
      margin-top: 30px;
    }
    ._address{
      color: $grey;
      margin: 0;
    }
    @media(min-width: $tablet){
      padding: 30px 30px 0 30px;
    }
  }
  ._slick-buttons{
    position: absolute;
    top: 42px;
    right: 0;
    display: none;
    @media(min-width: $tablet){
      display: flex;
    }
  }
}

._section-blog{
  ._heading{
    margin-bottom: 30px;
  }
  @include topBottomPadding;
  ._column{
    position: relative;
  }
  ._slick-buttons{
    position: absolute;
    top: 10px;
    right: 0;
  }
  ._blog-slider{
    margin-left: -15px;
    margin-right: -15px;
    ._blog-item{
      padding: 0 15px;
    }
  }
  ._flex-panel{
    max-width: 770px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    ._blog-item{
      width: 100%;
      margin-bottom: 60px;
    }
    @media(min-width: $tablet){
      column-gap: 30px;
      row-gap: 60px;
      ._blog-item{
        width: calc(50% - 15px);
        margin-bottom: 0;
      }
    }
  }
}

._blog-item{
  color: $light;
  ._secondary-heading{
    margin: 30px 0;
  }
  p{
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

._section-social-feed{
  @include bottomPadding;
}

._section-products{
  padding: 40px 0 100px 0;
  ._flex-box{
    @media(min-width: $tablet){
      display: flex;
      column-gap: 30px;
      ._filter{
        width: 270px;
      }
      ._results{
        width: calc(100% - 300px);
      }
    }
    @media(min-width: $laptop){
      
    }
  }
  ._product-list{
    ._column{
      margin-bottom: 20px;
    }
  }
  @media(min-width: $tablet){
    padding: 45px 0 150px 0;
    ._product-list{
      display: flex;
      column-gap: 30px;
      row-gap: 30px;
      flex-wrap: wrap;
      ._column{
        margin-bottom: 0;
        width: calc(50% - 15px);
      }
    }
  }
  @media(min-width: $laptop){
    padding: 60px 0 150px 0;
    ._product-list{
      ._column{
        width: calc(33% - 17.3px);
      }
    }
  }
}

._section-banner-product-overview{
  
  ._flex-box{
      @include wideBoxLayout;
      background-color: $veryDark;
  }
  ._image{
      background-position: center;
      background-size: cover;
      height: 200px;
      padding-bottom: 42%;
      height: 0;
  }
  ._intro{
      padding: 45px 20px;
      h1{
          margin: 0 0 15px 0;
          padding-bottom: 15px;
          border-bottom: 1px solid $borderColor;
      }
      ._table-type{
        margin-bottom: 30px;
        td{
          color: $light;
          &:nth-of-type(1){
            width: 30px;
          }
          &:nth-of-type(2){
            font-weight: 600;
            font-size: 20px;
          }
        }
        img{
          max-width: 25px;
          max-height: 25px;
        }
      }
      ._price{
        font-size: 30px;
        font-weight: 600;
        color: $gold;
      }
      ._qty{
        background-color: $veryDark;
        border: 1px solid $borderColor;
        height: 40px;
        border-radius: 0;
        width: 50px;
        margin-right: 10px;
        padding-left: 8px;
        color: $light;
      }
      ._flex{
        @media(min-width: $laptop){
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 30px;
        }
      }
      ._group{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        ._buy-now{
          white-space: nowrap;
          width: 155px;
          cursor: pointer;
        }
      }
      ._advice{
        // max-width: 163px;
        font-size: 16px;
        a{
          text-decoration: underline;
          font-weight: 600;
        }
      }
      @media(min-width: $tablet){
        h1{
          margin: 0 0 15px 0;
        }
        ._price{
          font-size: 35px;
        }
      }
  }
  ._product-page-buttons{
    ._group{
      column-gap: 15px;
      row-gap: 15px;
    }
  }

  
  @media(min-width:$tablet){
      ._flex-box{
        min-height: 500px;
        display: flex;
        height: auto;
        justify-content: flex-end;
        position: relative;
        ._image{
          position: absolute;
          left: 0;
        }
      }
      ._image{
          width: 50%;
          height: 100%;
          padding: 0;
      }
      ._intro{
          width: 50%;
          padding: 60px 40px;
          h1{
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 14px;
          }
          ._table-type{
            margin-top: 14px;
            margin-bottom: 15px;
          }
          ._price{
            margin-top: 30px;
            border-bottom: none;
            margin-bottom: 0;
            font-size: 40px;
            margin-bottom: 30px;
          }
          ._product-options-label{
            margin-top: 30px;
            margin-bottom: 6px;
          }
          ._product-options{
            width: 100%;
            padding-left: 10px;
          }
      }
      ._product-page-buttons{
        margin-top: 60px;
      }
  }
  @media(min-width:$laptop){
      ._image{
          width: 60%;
      }
      ._intro{
          width: 40%;
      }
  }
}

._types-flexbox{
  display: flex;
  column-gap:15px;
  row-gap: 15px;
  flex-wrap: wrap;
}

._type-flexbox{
  display: flex;
  column-gap:8px;
  color: $light;
  align-items: center;
  img{
    max-width: 30px;
    max-height: 30px;
  }
}

._table-shopping-cart{
  width: 100%;
  border-collapse: collapse;
  color: $light;
  th{
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: left;
    padding: 15px 0;
    color: $lightGrey;
  }
  td{
    border-top: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    padding: 15px 0;
  }
  ._subtotal{
    text-align: right;
  }
  ._qty input{
    background-color: $veryDark;
    border: 1px solid $borderColor;
    height: 40px;
    border-radius: 0;
    width: 50px;
    margin-right: 10px;
    padding-left: 8px;
    color: $light;
  }
  ._remove-button{
    padding: 0;
    background-color: transparent;
    border: none;
    p{
      margin: 0;
    }
  }
  @media(max-width: 767px){
    thead{
      display: none;
    }
    tr{
      border-bottom: 1px solid $borderColor;
    }
    td{
      display: block;
      text-align: right;
      clear: both;
      border: none;
      padding: 0;
      &._remove{
        padding: 15px 0 0 0;
      }
      &._product-image{
        text-align: center;
      }
      &._product-name{
        position: relative;
        &:before{
          content: "Product";
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          font-weight: 600;
          font-size: 20px;
        }
      }
      &._price{
        position: relative;
        &:before{
          content: "Price";
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          font-weight: 600;
          font-size: 20px;
        }
      }
      &._qty{
        display: flex;
        justify-content: flex-end;
        position: relative;
        color: $light;
        &:before{
          content: "Quantity";
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          font-weight: 600;
          font-size: 20px;
        }
      }
      &._subtotal{
        position: relative;
        &:before{
          content: "Subtotal";
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          font-weight: 600;
          font-size: 20px;
        }
      }
    }
  }
}

._table-after-shopping-cart{
  width: 100%;
  border-collapse: collapse;
  td{
    padding: 30px 0;
  }
  ._clear-cart{
    text-align: right;
  }
  @media(max-width: 767px){
    margin: 15px 0;
    td{
      display: block;
      padding: 15px 0;
    }
    ._clear-cart{
      text-align: center;
    }
  }
}

._section-cart{
  color: $light;
  @include topBottomPadding;
  ._flex-box{
    display: flex;
    width: 100%;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 15px 0 60px 0;
  }
  ._view-terms{
    a{
      text-decoration: underline;
    }
  }
  @media(min-width: $tablet){
    ._flex-box{
      margin: 30px 0 100px 0;
    }
    p{
      margin: 0;
    }
  }
}

._cart-totals{
  width: 100%;
  min-width: 300px;
  p{
    font-size: 20px;
  }
  td{
    white-space: nowrap;
  }
  ._checkout{
    width: 100%;
  }
  ._table-cart-totals{
    width: 100%;
    ._total{
      text-align: right;
      white-space: nowrap;
    }
  }
  ._table-details{
    td{
      padding: 10px 0;
      &:nth-of-type(1){
        padding-right: 30px;
      }
    }
  }
  @media(min-width: $tablet){
    max-width: 400px;
    td{
      padding: 30px 0;
    }
    ._table-details{
      td{
        padding: 10px 0;
        &:nth-of-type(1){
          padding-right: 30px;
        }
      }
    }
  }
}

._section-checkout{
  color: $light;
  @include bottomPadding;
  padding-top: 30px;
  @media(min-width:$laptop){
    padding-top: 60px;
  }
}

._checkout-order{
  background-color: $veryDark;
  padding: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  ._table-items{
    width: 100%;
    border-collapse: collapse;
    th{
      text-align: left;
      padding: 15px 0;
      border-bottom: 1px solid $borderColor;
      color: $lightGrey;
    }
    td{
      padding: 15px 0;
    }
    ._total{
      td{
        border-top: 1px solid $borderColor;
        border-bottom: 1px solid $borderColor;
      }
    }    
  }
  ._place-order{
    margin-top: 30px;
  }
}

._section-contact{
  @include topBottomPadding;
}

._section-banner-contact{
  color: $light;
  ._flex-box{
      @include wideBoxLayout;
      background-color: $veryDark;
  }
  ._map{
      height: 300px;
      padding-bottom: 300px;
      height: 0;
      position: relative;
      iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
  }
  ._intro{
      padding: 45px 20px;
      h1{
          margin-top: 0;
      }
      ._flex{
        display: flex;
        margin: 10px 0;
      }
      ._letter{
        color: $gold;
        padding-right: 10px;
      }
      a{
        font-weight: 300;
      }
  }
  @media(min-width:$tablet){
      ._flex-box{
          display: flex;
          height: 480px;
          align-items: center;
      }
      ._map{
          width: 50%;
          height: 100%;
          padding: 0;
      }
      ._intro{
          width: 50%;
          padding: 0 30px;
          max-height: calc(100% - 60px);
          overflow-y: auto;
      }
  }
  @media(min-width:$laptop){
      ._flex-box{
          height: 470px;
      }
      ._map{
          width: 60%;
      }
      ._intro{
          width: 40%;
          padding: 0 40px;
      }
  }
}

._section-product-information{
  @include topBottomPadding;
  ._panel{
    max-width: 770px;
    margin: 0 auto;
    color: $light;
    img{
      width: auto;
      max-width: 100%;
      margin: 30px auto;
      display: block;
    }
  }
}

._section-product-information-2{
  color: $light;
  @include bottomPadding;
  ._panel{
    max-width: 770px;
    margin: 0 auto;
  }
  
  ._tech-spec{
    text-align: center;
    position: relative;
    margin-bottom: 30px;
    h3{
      margin: 0;
    }
    p{
      margin: 10px 0 0 0;
      a{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    ._newsletter-form {
      width: 100%;
      ._form-row ._button{
        margin-top: 33px;
      }
    }
  }
  ._brand-logo{
    text-align: center;
    img{
      max-width: 237px;
      opacity: 0.4;
      @include transition;
    }
  }
  @media(min-width: $tablet){
    ._flex-box{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    ._tech-spec{
      text-align: left;
      margin-bottom: 0;
      p{
        a{
          justify-content: flex-start;
        }
      }
    }
  }
  @media(min-width: $laptop){
    ._brand-logo{
      img{
        &:hover{
          opacity: 1;
        }
      }
    }
  }
}

._newsletter-form {
  ._form-row ._button{
    margin-top: 33px;
  }
}

._section-gallery-slider{
  @include bottomPadding;
  ._gallery-slider{
    margin-left: -15px;
    margin-right: -15px;
  }
  ._img{
    padding: 0 15px;
    cursor: zoom-in;
  }
}

._payfast-gateway{
  background-color: #fff;
  height: 100vh;
  ._panel{
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 30px;
    img{
      max-width: 400px;
    }
    p{
      color: $dark;
      font-size: 20px;
    }
    ._button{
      width: 170px;
    }
  }
}

._section-blog-article{
  color: $light;
  @include bottomPadding;
  ._image-box{
    @include wideBoxLayout;
    background-color: $veryDark;
  }
  ._panel{
    max-width: 770px;
    margin: 0 auto;
  }
  ._blog-article-info{
    ._share{
      margin-bottom: 20px;
      ._flex{
        display: flex;
        column-gap: 15px;
      }
    }
    ._info{
      background-color: $veryDark;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      column-gap: 20px;
      width: 100%;
      h4{
        margin: 0;
      }
      p{
        margin: 0;
      }
    }
    @media(min-width: $tablet){
      display: flex;
      justify-content: space-between;
      ._share{
        margin-bottom: 0;
      }
      ._info{
        max-width: 370px;
      }
    }
  }
}

._section-my-account{
  color: $light;
  @include topBottomPadding;
  ._login, ._register{
    @media(min-width: $tablet){
      max-width: 500px;
      margin: 0 auto;
    }
  }
  ._customer-account-dashboard-nav{
    ul{
      list-style: none;
      padding-left: 0;
      a{
        padding: 10px 0;
        display: block;
        color: $light;
      }
    }
  }
  @media(min-width: $tablet){
    ._flex-box{
      display: flex;
      justify-content: space-between;
      column-gap: 60px;
      ._customer-account-dashboard-nav{
        width: 250px;
      }
      ._customer-account-dashboard-content{
        width: calc(100% - 300px);
      }
    }
  }
}

._section-admin-signin{
  color: $light;
  @include topBottomPadding;
  ._panel{
    max-width: 770px;
    margin: 0 auto;
  }
}

#techspec{
  visibility: hidden;
  opacity: 0;
  height: 0;
}

._section-not-found{
  @include topBottomPadding;
}

._section-product-video{
  @include bottomPadding;
  ._panel{
    max-width: 770px;
    margin: 0 auto;
  }
}

._section-thankyou{
  @include topBottomPadding;
  text-align: center;
  ._panel{
    margin: 0 auto;
    max-width: 770px;
  }
  p{
    font-size: 20px;
  }
}

._customer-orders-table{
    text-align: left;
    width: 100%;
    border-collapse: collapse;
    th, td{
      &:last-child{
        text-align: right;
      }
    }
    th{
      text-transform: uppercase;
      color: $grey;
      font-size: 14px;
      padding-bottom: 10px;
      color: $lightGrey;
    }
    td{
      border-top: 1px solid $borderColor;
      padding: 10px 0;
    }
}

._accept-terms{
  margin: 0;
}

._section-terms{
  color: $light;
  @include topBottomPadding;
  ul{
      padding-left: 0;
      list-style: none;
  }
  li{
      padding: 12px 0;
      font-weight: 300;
  }
  h2{
    margin: 0;
  }
  @media(min-width: $tablet){
      ._panel{
          max-width: 770px;
          margin: 0 auto;
      }
  }
}

._section-payment{
  @include topBottomPadding;
}

._checkout-notice{
  background-color: $veryDark;
  padding: 60px 30px;
  margin-top: 20px;
}

._blog-page-image{
  display: block;
}

._filter-info{
  border: 1px solid $borderColor;
  padding: 8px 10px;
  margin-bottom: 30px;
  p{
    margin: 0;
  }
}

._section-virtual-tour{
  position: relative;
  height: 100vh;
  iframe{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}