body{
    background-color: $dark;
}

._page-content{
    min-height: 80vh;
}

._grid{
    width: calc(100% - 40px);
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
    ._column{
        width: 100%;
    }
    &._form-grid{
        max-width: 800px;
    }
    &._form-grid-small{
        max-width: 400px;
    }
    &._grid-2{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(50% - 20px);
            }
        }
    }
    &._grid-3{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(33.33% - 30px);
            }
        }
    }
    &._grid-4{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(25% - 30px);
            }
        }
    }
    &._grid-fluid{
        max-width: 100%;
    }
    @media(min-width: $tablet){
        width: calc(100% - 60px);
        column-gap: 30px;
        row-gap: 30px;
    }
    @media(min-width: $laptop){
        width: calc(100% - 80px);
        column-gap: 40px;
        row-gap: 40px;
    }
}

._mobile{
    display: block;
    @media(min-width: $laptop){
        display: none;
    }
}

._widescreen{
    display: none;
    @media(min-width: $laptop){
        display: block;
    }
}

._header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: $darkOpaque;
    border-bottom: 1px solid $borderColor;
    z-index: 1000;
    ._flex-panel{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }
    
    ._logo{
        position: absolute;
        left: 50%;
        top: 50%;
        transform:translate(-50%, -50%);
    }
    > ._header-grid{
        height: 100%;
        position: relative;
        @include wideBoxLayout;
        > ._column{
            height: 100%;
        }
    }
    
}

._nav-group{
    height: 100%;
    display: flex;
    column-gap: 15px;
    @media(min-width: $desktop){
        column-gap: 30px;
    }
}
._user-cart-nav{
    ul{
        display: flex;
        list-style: none;
        padding-left: 0;
        margin: 0;
        height: 100%;
        li{
            height: 100%;
            a{
                height: 100%;
                display: flex;
                align-items: center;
                padding-left: 10px;
                img{
                    
                }
            }
        }
    }
}

._header-nav{
    height: 100%;
    > ul {
        height: 100%;
        align-items: center;
        display: flex;
        column-gap: 15px;
        margin: 0;
        list-style: none;
        padding-left: 0;
        > li{
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;
            > ._nav-link{
                height: 100%;
                color: $light;
                text-transform: uppercase;
                font-size: 15px;
                line-height: 18px;
                font-weight: 400;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }
    }
    @media(min-width: $desktop){
        > ul {
            column-gap: 45px;
        }
    }
}

._mobile-nav{
    position: fixed;
    top: 100px;
    height: calc(100vh - 100px);
    width: 100%;
    background-color: $darkOpaque;
    overflow: auto;
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    @include transition(0.25s);
    &._show{
        visibility: visible;
        opacity: 1;
        > ul{
            padding-top: 40px;
        }
    }
    > ul{
        height: 100%;
        min-height: 350px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 100px;
        ._mobile-nav-links{
            padding: 15px 0;
            display: block;
            color: $light;
            text-transform: uppercase;
            font-size: 18px;
            line-height: 18px;
            font-weight: 400;
        }
    }
    ul{
        padding-left: 0;
        margin: 0;
        list-style: none;
        @include transition(0.25s);
    }
    ._dropdown{
        overflow: hidden;
        visibility: visible;
        opacity: 1;
        height: auto;
    }

    ._dropdown-brands{
        overflow: hidden;
        visibility: visible;
        opacity: 1;
        height: auto;
    }
    ._hide{
        height: 0px;
        visibility: hidden;
        opacity: 0;
    }
}

._simple-dropdown{
    position: absolute;
    top: 100%;
    left: 0;
    background-color: $dark;
    color: $grey;
    text-align: center;
    list-style: none;
    padding-left: 0;
    border-left: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
    border-top: 1px solid $borderColor;
    a{
        color: $lightGrey;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 16px;
        display: block;
        padding: 14px 20px;
        border-bottom: 1px solid $borderColor;
        @media(min-width: $laptop){
            @include colorTransitionHover;
        }
    }
}

._mega-dropdown{
    position: fixed;
    top: 100px;
    left: 0;
    width: 100%;
    background-color: $dark;
    padding: 30px 0;
    z-index: 1000;
    border-bottom: 1px solid $borderColor;
    h2{
        margin-top: 0;
        color: $light;
        font-size: 20px;
        font-weight: 600;
        @media(min-width: $laptop){
            @include colorTransitionHover;
        }
    }
    ul{
        list-style: none;
        padding-left: 0;
        > li{
            > a{
                color: $lightGrey;
                font-weight: 300;
                font-size: 16px;
                display: block;
                padding: 5px 0;
                @media(min-width: $laptop){
                    @include colorTransitionHover;
                }
            }
        } 
    }
    ._flex-panel{
        display: flex;
        align-items: center;
        column-gap: 7%;
        row-gap: 30px;
        flex-wrap: wrap;
        justify-content: space-evenly;
        height: auto;
        ._flex-item{
            width: 12%;
            opacity: 0.6;
            @media(min-width: $laptop){
                opacity: 0.4;
                @include opacityTransitionHover;
            }
        }
    }
}

footer{
    background-color: $veryDark;
    padding-bottom: 100px;
    @include clipPathVShapeTop;
    ._flex-panel{
        @media(min-width: 550px){
            display: flex;
            flex-wrap: wrap;
            ._flex-item{
                width: 50%;
            }
            ._flex-item-flex-panel{
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                justify-content: space-between;
                > div{
                    width: auto;
                }
            }
            ._subcribe{
                width: 100%;
            }
        }
        @media(min-width: $laptop){
            flex-wrap: nowrap;
            column-gap: 60px;
            ._flex-item{
                width: auto;
            }
            ._flex-item-flex-panel{
                display: block;
                width: auto;
            }
        }
        ._flex-item{
            
            margin: 30px 0;
            ul{
                padding-left: 0;
                list-style: none;
                li{
                    a{
                        color: $lightGrey;
                        font-size: 15px;
                        font-weight: 300;
                        display: block;
                        padding: 4px 0;
                        white-space: nowrap;
                        @media(min-width: $laptop){
                            @include colorTransitionHover;
                        }
                    }
                }
            }
            p{
                color: $lightGrey;
                font-size: 15px;
                font-weight: 300;
            }
        }
    }
    ._logo{
        max-width: 200px;
    }
    h3{
        margin-top: 0;
        color: $gold;
        font-size: 16px;
        white-space: nowrap;
    }
}

._footer-bottom{
    padding: 0 0 30px 0;
    text-align: center;
    position: relative;
    @media(min-width: 500px){
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
    }
    p{
        color: $lightGrey;
        margin: 0;
        a{
            @media(min-width: $laptop){
                @include colorTransitionHover;
            }
        }
    }
    ._social-icons{
        position:unset;
        @media(min-width: $tablet){
            position: absolute;
            top: 0;
        }
    }
}

