$font: futura-pt, sans-serif;

$tablet: 768px;
$laptop: 1124px;
$desktop: 1300px;

$dark: #181818;
$veryDark: #0F0F0F;
$light: #F0F0F0;
$lighter: #fcfafa;
$themeColor: #EE9B38;
$themeColorDarker: #ad7127;
$darkOpaque: rgba(0,0,0,0.9);
$grey: #82828a;
$lightGrey: #c5c5c7;
$gold: #FC9602;
$borderColor: #2F2F2F;