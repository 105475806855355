@mixin transition($speed: 0.25s, $delay: 0s){
    transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    transition: $speed;
    -o-transition: $speed;
    -moz-transition: $speed;
    -webkit-transition: $speed;
    transition-delay: $delay;
    -moz-transition-delay: $delay;
    -o-transition-delay: $delay;
    -webkit-transition-delay: $delay;
}

@mixin boxShadow {
    box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.2);
}

@mixin colorTransitionHover{
    @include transition;
    &:hover{
        color: $gold;
    }
}

@mixin opacityTransitionHover {
    @include transition;
    &:hover{
        opacity: 1;
    }
}

@mixin clipPathVShapeTop{
    clip-path: polygon(50% 2.2vw,100% 0%,100% 100%,0% 100%,0% 0%);
    margin-top: -20px;
    @media(min-width: $tablet){
        padding-top: 30px;
        margin-top: -30px;
    }
    @media(min-width: $laptop){
        padding-top: 45px;
        clip-path: polygon(50% 30px,100% 0%,100% 100%,0% 100%,0% 0%);
    }
}

@mixin clipPathVShapeBottom{
    clip-path: polygon(0% 0%,100% 0%,100% calc(100% - 55px),50% calc(100% - 22px),0% calc(100% - 55px));
    @media(min-width: $laptop){
        clip-path: polygon(0% 0%,100% 0%,100% 94%,50% calc(100% - 18px),0% 94%);
    }
}

@mixin clipPathSkewBlock{
    clip-path: polygon(0% 0%,100% 5%,100% 100%,0% 96%);
}

@mixin topPadding{
    padding-top: 60px;
    @media(min-width: $laptop){
        padding-top: 100px;
    }
}

@mixin bottomPadding{
    padding-bottom: 60px;
    @media(min-width: $laptop){
        padding-bottom: 100px;
    }
}

@mixin topBottomPadding{
    padding: 60px 0;
    @media(min-width: $laptop){
        padding: 100px 0;
    }
}

@mixin topBottomPaddingExtra{
    padding: 100px 0;
    @media(min-width: $laptop){
        padding: 150px 0;
    }
}

@mixin topBottomPaddingOverlap{
    padding: 200px 0 60px 0;
    margin: -200px 0 0 0;
    @media(min-width: $laptop){
        padding: 200px 0 100px 0;
    }
}

@mixin overlayGradient{
    background: rgb(0,0,0);
    background: linear-gradient(20deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 30%);
}

@mixin overlayGradient2{
    background: rgb(0,0,0);
    background: linear-gradient(5deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 70%);
}


@mixin customCursor{
    @media(min-width: $laptop){
        // cursor: url('../img/Icons/best-price-icon.svg') 50 50, pointer;
        cursor: url('../img/Icons/view-more-button-new-2.svg') 50 50, pointer;
    }
}

@mixin wideBoxLayout{
    width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;
    @media(min-width: $tablet){
        width: calc(100% - 60px);
    }
    @media(min-width: $laptop){
        width: calc(100% - 80px);
    }
}